<template>
  <div class="TrainingCamp">
    <ul>
      <li>
        <img src="../assets/TrainingCamp/01.jpg" alt="" />
      </li>
      <li>
        <img src="../assets/TrainingCamp/02.jpg" alt="" />
      </li>
      <li>
        <img src="../assets/TrainingCamp/03.jpg" alt="" />
      </li>
           <li>
        <img src="../assets/TrainingCamp/04.jpg" alt="" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style>
img {
  width: 100%;
  height: 100%;
  vertical-align: top;
}
</style>